<template>
    <div :class="{'d-inline-flex':!isMobile}">
        <v-container>
        <m-dialog v-model="dialog" title="Bond">
            <bond-report v-model="bond"></bond-report>
        </m-dialog>
        <page-card title="Bond Search" icon="mdi-cash">
            <v-card-text>
                <div>
                    <v-pagination
                    v-model="page"
                    :length="Math.ceil(data.foundRows/data.limit)"
                    :total-visible="7"
                    ></v-pagination>
                </div>
                <m-table :headers = "headers" :data = "data.data" v-on:rowClick="openBondDialog"></m-table>
                </v-card-text>
        </page-card>
        </v-container>
    </div>
</template>

<script>
    import apiMixin from '../mixins/apiMixin'
    import appSearch from '../mixins/appSearchMixin'
    import overLoading from '../mixins/overLoading'
      

      
    //   import CustomerDialog from '../components/CustomerDialog'




      export default {
          name: "Bonds",
          components: {
          },
          mixins: [apiMixin, appSearch, overLoading],
          data() {
            return {
                dialog: false, 
                page: 1,
                headers: [
                    {label: "Status",         key: "DISP",        type: "text"},
                    {label: "Agent",          key: "AGENT",       type: "text"},
                    {label: "Exec Date",      key: "DATE_EXE",    type: "date"},
                    {label: "Pre",            key: "ALPHA_PRE",   type: "text"},
                    {label: "Number",         key: "POWER",       type: "text"},
                    {label: "Liability",      key: "BOND_AMT",    type: "currency"},
                    {label: "Case Number",    key: "CASENUMBER",  type: "text"},
                    {label: "Court",          key: "COURT",       type: "text"},
                    {label: "County",         key: "COUNTY",      type: "text"},
                    {label: "Last Name",      key: "LAST_NAME",   type: "text"},
                    {label: "First Name",     key: "FIRST_NAME",  type: "text"}
                ],
                data: [],
                bond: null
            }
          },
          watch: {
              dialog (val) {
                  this.bond = (val) ? this.bond :  null
              },
              page: function() {
                  this.getCustomers()
              },
              appSearch () {
                  this.page = 1
                  this.getCustomers()
              }
          },
          created() {
            this.getCustomers()
          },
          methods :{
            openBondDialog(bond) {
                this.dialog= true
                this.bond = bond
            },
            async getCustomers () {
                this.overLoading = true
                console.log("getting applicattions")
                let rowCount = 50
                let offset = (this.page - 1) * rowCount
                var payload =  {
                    search: {
                        expression :"concat(IFNULL(LAST_NAME,''), ', ', IFNULL(FIRST_NAME,''), ' ',IFNULL(ALPHA_PRE,''), ' ', IFNULL(POWER,''), ' ',IFNULL(AGENT,''), ' ',IFNULL(CASENUMBER,''), ' ')",
                        pattern:  "%" + this.appSearch + "%"
                    },
                    table: "bond",
                    offset: offset,
                    rowCount: rowCount,
                    orderBy: "DATE_EXE DESC"
                }
                
                // this.data = await this.apiRead(payload)
                if (this.appSearch) {
                    this.apiRead(payload).then((response) => {
                        this.data = response
                        this.overLoading = false
                    })
                } else {
                    this.data = []
                    this.overLoading = false
                }
            },
          }

      }
</script>